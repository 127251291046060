<template>
  <div class="app-container">
    <div class="content">
      <h1>SanQua HRIS</h1>
      <p>Klik tombol di bawah untuk mendownload aplikasi Android.</p>

      <div class="d-flex justify-center align-center flex-column">
        <!-- Link download -->
        <v-btn type="button" color="black" @click="downloadLink">
          <v-icon color="white" style="margin-right: 5px">mdi-android</v-icon>
          <span style="color: white"> download</span>
        </v-btn>
        <span style="font-size: 12px">Version 1.5.3+41</span>
      </div>

      <div class="tutorial">
        <h2>Langkah-langkah Instalasi</h2>
        <ol>
          <li>Uninstall aplikasi HRIS yang lama</li>
          <li>Unduh APK dari tombol di atas.</li>
          <li>Jika file sudah diunduh, buka file APK.</li>
          <li>
            Izinkan instalasi aplikasi dari sumber tidak dikenal dengan membuka
            pengaturan > Keamanan > Sumber tidak dikenal.
          </li>
          <li>Klik "Instal" dan tunggu hingga aplikasi terpasang.</li>
          <li>Setelah selesai, buka aplikasi dari layar beranda.</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    // Ganti dengan URL file APK Anda
    apkDownloadLink: 'http://bit.ly/4h9JsjN'
  }),
  methods: {
    downloadLink() {
      window.open(this.apkDownloadLink, '_blank')
    }
  }
}
</script>

<style scoped>
.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.content {
  text-align: center;
  max-width: 600px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  color: indigo;
}

.download-button {
  background-color: #222222;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  text-decoration: none;
  border-radius: 10px;
  display: inline-block;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #5c5c5c;
}

.tutorial {
  margin-top: 30px;
  text-align: left;
}

.tutorial ol {
  padding-left: 20px;
}

.tutorial h2 {
  color: #333;
}

.tutorial li {
  margin-bottom: 10px;
}
</style>
